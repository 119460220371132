//import { get, post } from "./api.service";
import axios from 'axios';


const marketId = 3; //marketplace ID

export async function initMarketplace() {
  try {
    const { data } = await axios.get("marketplace_init/?m="+marketId);
    return [null, data];
  } catch (error) {
    console.log(error);
    return [error];
  }
}

export async function getCourses() {
  try {
    const { data } = await axios.get("courses?m="+marketId);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getCategoryCourses(catId) {
  try {
    console.log(catId);
    const { data } = await axios.get("get_categorized_courses/" + catId+"?m="+marketId);
    return data;
  } catch (error) {
    return error;
  }
}

export async function courseEnroll(path, payload) {
    const { data } = await axios.post(path, payload);
    return data;
}

export async function registerInterest(payload){
    const {data} = await axios.post('register-interest', payload);
    return data;
}
  

export async function signUpAsGuest(payload) {
  try {
    const { response } = await axios.post("guest_signup", payload);
    return [null, response];
  } catch (error) {
    return [error];
  }
}
