<template>
  <header class="h-16 2xl:px-10 bg-pink-600 text-primary-content sticky top-0 shadow-md z-10">
    <div class="p-2 mx-auto text-left flex w-full items-center">
      <div class="text-3xl text-white flex-1"><span class="font-black">JL</span><span class="font-thin text-lg"> private marketplace</span></div>
      <div v-if="!isAuthenticated">
        <FlatMiniButton backgroundColor="bg-secondary" @click="login">Login</FlatMiniButton>
      </div>
      <template v-else>
        <div class="h-8 w-8 border border-white rounded-full flex overflow-hidden">
          <img :src="getUserData.profile_photo_url" class="object-cover" />
        </div>
        <div class="pl-2 text-sm font-bold text-white cursor-pointer" @click="logoutAction">Logout</div>
      </template>
    </div>
  </header>
  <div class="flex 2xl:px-10">
    <aside class="h-screen sticky top-16 w-80">
      <slot name="sidebar"></slot>
    </aside>
    <div @loginAction="login">
      <slot></slot>
    </div>
  </div>
</template>
<script>
//import { checkAuth } from '@/data/pandai.auth';
import FlatMiniButton from '@/components/FlatMiniButton.vue';
import { mapGetters, mapActions } from 'vuex';
import { authUri } from '@/data/appdata';
export default {
  data() {
    return {
      test: null,
    }
  },
  computed: {
    ...mapGetters([
      'getUserData',
      'getAuthToken',
      'isAuthenticated'
    ])
  },  
  watch: {
    getAuthToken: function (v) {
      console.log(v)
    }
  },
  methods: {
    ...mapActions([
      'logoutAction'
    ]),
    login() {
      //const authUri = 'http://pandaisuite.local/oauth/authorize_access?target=http://192.168.111.40:8080/pandai_auth/';
      /*       var w = 460;
            var h = 540;
            var left = (screen.width) - w;
            var top = (screen.height/2)-(h/2);
            return window.open(authUri, 'Login With PandaiSuite', 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left); */
      return window.open(authUri, 'newwindow', 'width=500, height=500');
    }
  },
  /* async created() {
      const data = await checkAuth();
      console.log(data);
  }, */
  components: { FlatMiniButton }
}
</script>
<style>
</style>